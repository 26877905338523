<template>
    <div class="DealerHeader">
        <div class="nav-wrapper">
            <div class="container p-0">
                <nav class="navbar navbar-expand-md navbar-light bg-ligh">
                    <button ref="navbarToggler" class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <router-link class="navbar-brand" :to=" { name: 'Home' } ">
                        <img src="@/assets/images/logo.png" class="logo" alt="Лукойл">
                    </router-link>
                    <div class="collapse" ref="mobileMenu" id="navbarNavDropdown">
                        <ul class="menu-mobile">
                          <template v-if="availablePage">
                            <li @click=" collapseMenu " class="menu-item" v-for=" item  in sectionsList" :key=" item.name ">
                              <router-link :to=" /catalog/ + item.slug ">{{ item.name }}</router-link>
                            </li>
                          </template>
                            <li @click=" collapseMenu " class="menu-item">
                                <static-page class="sub-menu__item-link" title="Обучение" slug="/education" />
                            </li>
                            <li v-if="availablePage" @click=" collapseMenu " class="menu-item">
                                <static-page class="sub-menu__item-link" type="4" slug="/visits" />
                            </li>
                            <li @click=" collapseMenu " class="menu-item">
                                <static-page type="1" slug="/delivery" />
                            </li>
                            <li @click=" collapseMenu " class="menu-item">
                                <static-page type="2" slug="/how-to-order" />
                            </li>
                            <li @click=" collapseMenu " class="menu-item">
                                <router-link to="/profile">Личный кабинет</router-link>
                            </li>
                            <li @click=" collapseMenu " class="menu-item">
                                <static-page type="3" slug="/about" />
                            </li>
                        </ul>
                    </div>
                    <div class="navbar-collapse">
                        <div class="ml-auto d-none d-lg-block">
                            <img src="@/assets/images/dealer-cup.svg" alt="cup" width="258">
                        </div>
                        <ul class="navbar-nav ml-auto flex-end-sm">
                            <li class="nav-item">
                                <span class="ooo">{{ accountInfo.name }}</span>
                                <span class="coins">Баланс: {{ pointsNumber }} балл</span>
                            </li>
                            <li class="nav-item bag">
                                <router-link :to=" { name: 'DealerCart' } " class="nav-link" :data-count=" itemsInCart ">
                                    <span class="itemsTooltip">Корзина</span>
                                    <svg viewBox="0 0 24 24" width="24px" height="24px">
                                        <g id="404" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"
                                            transform="translate(-977 -22)">
                                            <g id="Group-8" fill="#ffffff" fill-rule="nonzero">
                                                <g id="Group-4" transform="translate(977 22)">
                                                    <g id="bag">
                                                        <path id="Shape"
                                                            d="M 21.1925 6.28424 C 21.1306 5.8708 20.7796 5.5814 20.3668 5.5814 L 15.6188 5.5814 L 15.6188 3.63824 C 15.6188 1.63307 13.988 0 11.9855 0 C 9.98314 0 8.35231 1.63307 8.35231 3.63824 L 8.35231 5.5814 L 3.60434 5.5814 C 3.19147 5.5814 2.84053 5.8708 2.7786 6.28424 L 0.012386 23.0284 C -0.0289007 23.2765 0.0330294 23.5245 0.198177 23.7106 C 0.363324 23.8966 0.590401 24 0.838121 24 L 23.133 24 C 23.133 24 23.133 24 23.1536 24 C 23.6284 24 24 23.6279 24 23.1525 C 24 23.0491 23.9794 22.9664 23.9587 22.8837 L 21.1925 6.28424 Z M 10.0451 3.63824 C 10.0451 2.56331 10.9121 1.69509 11.9855 1.69509 C 13.059 1.69509 13.926 2.56331 13.926 3.63824 L 13.926 5.5814 L 10.0451 5.5814 L 10.0451 3.63824 Z M 1.829 22.3256 L 4.32685 7.27649 L 8.35231 7.27649 L 8.35231 12.0103 C 8.35231 12.4858 8.7239 12.8579 9.19869 12.8579 C 9.67349 12.8579 10.0451 12.4858 10.0451 12.0103 L 10.0451 7.27649 L 13.926 7.27649 L 13.926 12.0103 C 13.926 12.4858 14.2976 12.8579 14.7724 12.8579 C 15.2472 12.8579 15.6188 12.4858 15.6188 12.0103 L 15.6188 7.27649 L 19.6442 7.27649 L 22.1421 22.3256 L 1.829 22.3256 Z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a @click=" logout " class="nav-link" role="button">
                                    <span class="logoutTooltip">Выход</span>
                                    <svg viewBox="0 0 24 24" width="24px" height="24px">
                                        <g id="404" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"
                                            transform="translate(-1031 -22)">
                                            <g id="Group-8" fill="#ffffff" fill-rule="nonzero">
                                                <g id="logout" transform="translate(1031 22)">
                                                    <path id="Shape"
                                                        d="M 16.9436 18.1333 C 17.1077 18.2974 17.3128 18.3795 17.5385 18.3795 C 17.7641 18.3795 17.9692 18.2974 18.1333 18.1333 L 23.6718 12.5949 C 23.7128 12.5538 23.7538 12.5128 23.7744 12.4718 C 23.7744 12.4718 23.7949 12.4513 23.7949 12.4308 C 23.8154 12.3897 23.8359 12.3487 23.8564 12.3282 C 23.8564 12.3077 23.8564 12.2872 23.8769 12.2872 C 23.8974 12.2462 23.8974 12.2256 23.9179 12.1846 C 23.9385 12.1231 23.9385 12.0821 23.9385 12.0205 C 23.9385 11.959 23.9385 11.9179 23.9179 11.8564 C 23.9179 11.8154 23.8974 11.7744 23.8769 11.7538 C 23.8769 11.7333 23.8769 11.7128 23.8564 11.7128 C 23.8359 11.6718 23.8154 11.6308 23.7949 11.5897 C 23.7949 11.5897 23.7949 11.5692 23.7744 11.5692 C 23.7538 11.5282 23.7128 11.4872 23.6718 11.4462 L 18.1333 5.90769 C 17.8051 5.57949 17.2718 5.57949 16.9436 5.90769 C 16.6154 6.2359 16.6154 6.76923 16.9436 7.09744 L 21.0462 11.2 L 6.46154 11.2 C 5.98974 11.2 5.62051 11.5692 5.62051 12.041 C 5.62051 12.5128 5.98974 12.8821 6.46154 12.8821 L 21.0462 12.8821 L 16.9436 16.9846 C 16.6154 17.2718 16.6154 17.8051 16.9436 18.1333 Z">
                                                    </path>
                                                    <path id="Shape"
                                                        d="M 0.923077 23.8974 L 12 23.8974 C 12.4718 23.8974 12.841 23.5282 12.841 23.0564 L 12.841 14.7897 C 12.841 14.3179 12.4718 13.9487 12 13.9487 C 11.5282 13.9487 11.159 14.3179 11.159 14.7897 L 11.159 22.2154 L 1.7641 22.2154 L 1.7641 1.7641 L 11.159 1.7641 L 11.159 9.18974 C 11.159 9.66154 11.5282 10.0308 12 10.0308 C 12.4718 10.0308 12.841 9.66154 12.841 9.18974 L 12.841 0.923077 C 12.841 0.451282 12.4718 0.0820513 12 0.0820513 L 0.923077 0.0820513 C 0.451282 0.0820513 0.0820513 0.451282 0.0820513 0.923077 L 0.0820513 23.0564 C 0.102564 23.5282 0.471795 23.8974 0.923077 23.8974 Z">
                                                    </path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
        <div class="menu-wrapper d-none d-md-block">
            <div class="container">
                <ul class="menu">
                    <li v-if="availablePage" class="menu-item menu-item--drop-down">
                        <a class="arrow-down">
                            Каталог товаров
                            <i class="arrow-down__child">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="9px" height="5px">
                                    <path fill-rule="evenodd" fill="rgb(74, 74, 74)"
                                        d="M7.950,-0.000 L4.500,3.108 L1.050,-0.000 L-0.000,0.946 L4.500,5.000 L9.000,0.946 L7.950,-0.000 Z" />
                                </svg>
                            </i>
                        </a>
                        <div class="drop-down-container">
                            <div class="drop-down-container__flex">
                                <ul class="sub-menu" v-if=" sectionsList ">
                                    <li class="sub-menu__item" v-for=" item  in sectionsList" :key=" item.name ">
                                        <router-link class="sub-menu__item-link" :to=" /catalog/ + item.slug ">{{
                                                item.name
                                        }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="menu-item menu-item--drop-down">
                        <a class="arrow-down">
                            Каталог услуг
                            <i class="arrow-down__child">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="9px" height="5px">
                                    <path fill-rule="evenodd" fill="rgb(74, 74, 74)"
                                        d="M7.950,-0.000 L4.500,3.108 L1.050,-0.000 L-0.000,0.946 L4.500,5.000 L9.000,0.946 L7.950,-0.000 Z" />
                                </svg>
                            </i>
                        </a>
                        <div class="drop-down-container">
                            <div class="drop-down-container__flex">
                                <ul class="sub-menu">
                                    <li class="sub-menu__item">
                                        <static-page title="Обучение" class="sub-menu__item-link" slug="/education" />
                                    </li>
                                    <li v-if="availablePage" class="sub-menu__item">
                                        <static-page class="sub-menu__item-link" type="4" slug="/visits" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="menu-item">
                        <static-page type="1" slug="/delivery" />
                    </li>
                    <li class="menu-item">
                        <static-page type="2" slug="/how-to-order" />
                    </li>
                    <li class="menu-item">
                        <router-link :to=" { name: 'Profile' } ">Личный кабинет</router-link>
                    </li>
                    <li class="menu-item">
                        <static-page type="3" slug="/about" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import 'bootstrap/js/src/collapse';
import StaticPage from "../../components/static-pages/StaticPage";
import { mapGetters, mapActions } from 'vuex';
import usersWithDisablePages from "@/config/users_with_disable_pages";

export default {
    name: "DealerHeader",
    components: { StaticPage },
    async mounted ()
    {
        await this.getUserInfo();
        await this.fetchSectionList();
        await this.fetchStaticList();
        await this.fetchCloseOrdersDealerByDate();
    },
    data: () => ( {} ),
    methods: {
        ...mapActions( 'account', [ 'getUserInfo' ] ),
        ...mapActions( 'catalog', [ 'fetchSectionList' ] ),
        ...mapActions( 'account', [ 'logout' ] ),
        ...mapActions( 'staticPages', [ 'fetchStaticList' ] ),
        ...mapActions( 'builtInParameter', [ 'fetchCloseOrdersDealerByDate' ] ),
        collapseMenu ()
        {
            if ( this.$refs.mobileMenu && this.$refs.mobileMenu.classList.contains( 'show' ) )
                this.$refs.navbarToggler.click();
        }
    },
    computed: {
        ...mapGetters( 'account', [ 'accountInfo', 'pointsNumber' ] ),
        ...mapGetters( 'cart', [ 'itemsInCart' ] ),
        ...mapGetters( 'catalog', [ 'sectionsList' ] ),
        ...mapGetters( 'staticPages', [ 'staticList' ] ),
        ...mapGetters( 'builtInParameter', [ 'closeOrdersDealerByDate' ] ),
        availablePage() {
          if (this.accountInfo.dealerUniqueId) {
            return !usersWithDisablePages.includes(+this.accountInfo.dealerUniqueId)
          }
          return true
        },
    },
}
</script>

<style scoped>
.nav-wrapper {
    background: black;
}

.nav-wrapper nav {
    background: none;
    color: #ffffff;
}

nav .logo {
    width: 140px;
    height: auto;
}

.nav-item {
    margin-left: 28px;
}

.nav-item .nav-link {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
}

.nav-item span {
    display: block;
    margin-top: 3px;
}

.nav-item span.ooo {
    font-size: 18px;
    line-height: 1;
    text-align: left;
    color: #ffffff;
}

.nav-item span.coins {
    font-size: 18px;
    line-height: 1;
    text-align: left;
    color: #e5173f;
}

.bag a {
    position: relative;
}

.bag a:after {
    content: attr(data-count);
    position: absolute;
    top: 5px;
    right: -13px;
    background-color: #e5173f;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    display: block;
    text-align: center;
    line-height: 1.8;
}

.menu-wrapper {
    display: flex;
    background-color: #eff1f6;
    padding: 5px 0;
}

.menu {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.menu-item {
    padding: 0 20px;
}

.menu-item a {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    padding: 11px 0;
    display: block;
    box-sizing: border-box;
    position: relative;
    text-decoration: none;
}

.menu-item a.active:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #e5173f;
    position: absolute;
    bottom: 0;
    left: 0;
}

.menu-item a:not(.active):hover::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgba(229, 23, 63, 0.44);
    position: absolute;
    bottom: -4px;
}

.menu-item:last-child {
    padding-right: 0;
}

.menu-item:first-child {
    padding-left: 0;
}

@media (min-width: 577px) and (max-width: 991px) {
    .navbar {
        flex-wrap: inherit;
        align-items: flex-start;
        padding: .5rem 0.3rem;
    }

    .navbar-nav {
        flex-direction: row;
        justify-content: space-around;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767px) {
    nav .logo {
        width: 100px;
        padding-top: 4px;
    }

    .navbar {
        flex-wrap: inherit;
        align-items: flex-start;
        padding: 0.6rem;
    }

    .flex-end-sm {
        justify-content: flex-end;
    }

    .navbar-nav {
        flex-direction: row;
        justify-content: space-around;
    }

    .nav-item {
        margin-left: 15px;
    }

    .nav-item svg {
        width: 20px;
        height: 20px;
    }

    .nav-item span {
        font-size: 12px;
    }

    .navbar-brand {
        padding-top: 0;
        padding-left: 15px;
    }

    #navbarNavDropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 10;
        margin: 0 -0.3rem;
        background-color: #eff1f6;
        box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2);
    }

    .navbar-toggler .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        margin-top: 5px;
        background-color: #fff;
    }

    .navbar-toggler[aria-expanded=true]:before {
        content: '';
        width: 50px;
        height: 55px;
        display: block;
        background: #eff1f6;
        position: absolute;
        left: -5px;
        top: -8px;
        z-index: 0;
    }

    .navbar-toggler[aria-expanded=true] .icon-bar {
        position: relative;
        z-index: 2;
        background-color: black;
    }

    .navbar-toggler {
        padding: .25rem 0.55rem;
        position: relative;
        background-color: transparent;
        border: none;
    }

    .navbar-toggler:focus {
        outline: none;
    }

    .menu-mobile {
        list-style: none;
        padding: 0px;
        margin: 20px 0;
    }

    .menu-mobile .menu-item a {
        text-align: left;
        padding: 15px 40px;
    }

    .menu-mobile .menu-item a.active {
        background-color: #b3b3b3;
    }

    .menu-mobile .menu-item a:hover {
        background-color: #eaeaea;
    }

    .menu-mobile .menu-item a:after {
        content: '';
        display: none;
    }

    .menu-mobile .menu-item {
        padding: 0;
    }

    .navbar-nav.ml-auto li:first-child {
        padding-top: 3px;
    }
}

@media (max-width: 380px) {

    .nav-item {
        margin: 0 20px;
    }

    .nav-item:first-child {
        display: none;
    }
}

.menu-mobile .menu-item a:hover {
    background-color: #eff1f6;
}

a .itemsTooltip,
a .logoutTooltip {
    visibility: hidden;
    width: 20px;
    font-family: 'Arial', Arial, sans-serif;
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-top: 30px;
    margin-left: -25px;
    position: absolute;
}

a:hover .itemsTooltip,
a:hover .logoutTooltip {
    visibility: visible;
}

.menu-item--drop-down,
.arrow-down {
    position: relative;
    top: 0;
    left: 0;
}

.menu-item--drop-down {
    z-index: 100;
    cursor: pointer;
}

.menu-item--drop-down:nth-of-type(2) .drop-down-container {
    left: -20px;
}

.arrow-down__child {
    position: absolute;
    top: 9px;
    right: -22px;
}

.drop-down-container {
    display: block;
    position: absolute;
    top: 40px;
    left: -45px;
    width: 100%;
}

.drop-down-container__flex {
    display: block;
    width: 300px;
    padding-bottom: 20px;
    background: #eff1f6;
    box-shadow: 0 39px 67px -49px rgba(0, 0, 0, 0.53);
}

.menu-item--drop-down:nth-of-type(2) .drop-down-container {
    box-shadow: 0 40px 44px -23px rgba(0, 0, 0, 0.33);
}

.drop-down-container {
    display: none;
}

.menu-item--drop-down:hover .drop-down-container {
    display: block;
}

.sub-menu {
    display: block;
    margin: 0;
    list-style: none;
    width: 100%;
    padding: 7px 24px;
}

.sub-menu__item {
    border-bottom: solid #dfdfdf 1px;
}

.sub-menu__item:last-child {
    border-bottom: solid transparent 1px;
}

.sub-menu__item-link {
    display: block;
    text-align: left;
    padding: 20px 20px;
    transition: background 0.2s linear, color 0.2s linear;
}

.sub-menu__item-link:hover {
    background: #d2233c;
    color: #fff;
}

.sub-menu__item-link:hover:after {
    display: none;
}

@media only screen and (max-width: 991px) {
    .drop-down-container {
        top: 58px;
    }
}

.g-hide {
    display: none;
}
</style>
